// * Angular
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ThemeService {
    openCheckbox: number = 0;
    theme: string;

    constructor() {
        this.theme = localStorage.getItem('theme') || 'light';

        document.body.classList.toggle('theme--dark', this.theme === 'dark');

        document.body.classList.toggle('theme--light', this.theme === 'light');

        const theme = localStorage.getItem('theme');

        if (!theme) localStorage.setItem('theme', 'light');
    }

    changeMode(theme: string): void {
        this.theme = theme;

        this.theme = this.theme === 'light' ? 'light' : 'dark';

        document.body.classList.toggle('theme--dark', this.theme === 'dark');

        document.body.classList.toggle('theme--light', this.theme === 'light');

        localStorage.setItem('theme', this.theme);

        this.openCheckbox = 0;
    }

	// Cambiar al modo claro
	enableLightMode() {
		document.body.classList.remove('dark-mode');
		document.body.classList.add('light-mode');
	}

	  // Cambiar al modo oscuro
	enableDarkMode() {
		document.body.classList.remove('light-mode');
		document.body.classList.add('dark-mode');
	}

	// Nuevo método para aplicar el tema inicial
	initializeTheme(): void {
        const savedTheme = localStorage.getItem('theme') || 'light';
        this.applyTheme(savedTheme);
    }

    // Método privado para aplicar clases de tema
    private applyTheme(theme: string): void {
        document.body.classList.toggle('theme--dark', theme === 'dark');
        document.body.classList.toggle('theme--light', theme === 'light');
    }
}
